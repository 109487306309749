<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Custom Styles
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin">
                    For custom form validation, we used the
                    <code>Vuelidate</code> framework which is a simple,
                    lightweight model-based validation for Vuejs. For each value
                    you want to validate, you have to create a key inside
                    validations options. You can specify when input becomes
                    dirty by using appropriate event on your input box. More
                    details on the
                    <a
                      href="https://vuelidate.js.org/#getting-started"
                      rel="nofollow"
                      class="text-decoration-none text-primary text-body-2 ls-0 font-weight-600"
                      target="_blank"
                      >Documentation</a
                    >. <br /><br />
                    When attempting to submit, you’ll see the<code
                      >.error--text</code
                    >
                    classes applied to your form controls.
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <v-form class="mt-13">
                <v-row>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >First Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="First Name"
                      v-model="firstName"
                      :error-messages="firstNameErrors"
                      :counter="10"
                      required
                      @input="$v.firstName.$touch()"
                      @blur="$v.firstName.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Last Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="First Name"
                      v-model="lastName"
                      :error-messages="lastNameErrors"
                      :counter="10"
                      required
                      @input="$v.lastName.$touch()"
                      @blur="$v.lastName.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Username</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Username"
                      v-model="username"
                      :error-messages="usernameErrors"
                      :counter="10"
                      required
                      @input="$v.username.$touch()"
                      @blur="$v.username.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >City</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="City"
                      v-model="city"
                      :error-messages="cityErrors"
                      :counter="10"
                      required
                      @input="$v.city.$touch()"
                      @blur="$v.city.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >State</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="State"
                      v-model="state"
                      :error-messages="stateErrors"
                      :counter="10"
                      required
                      @input="$v.state.$touch()"
                      @blur="$v.state.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Zip</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Zip"
                      v-model="zip"
                      :error-messages="zipErrors"
                      required
                      @input="$v.zip.$touch()"
                      @blur="$v.zip.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-checkbox
                  v-model="checkbox"
                  :error-messages="checkboxErrors"
                  color="#5e72e4"
                  :ripple="false"
                  class="ma-0 checkbox-custom checkbox-thinner mb-5"
                  required
                  hide-details
                  @change="$v.checkbox.$touch()"
                  @blur="$v.checkbox.$touch()"
                >
                  <template v-slot:label>
                    <span class="text-body text-body-2 ls-0 checkbox-validation"
                      >Agree to terms and conditions</span
                    >
                  </template>
                </v-checkbox>

                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                  >Submit</v-btn
                >

                <v-btn
                  @click="clear"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm"
                  color="#5e72e4"
                  >Clear</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Browser Defaults
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin">
                    Not interested in custom validation feedback messages or
                    writing JavaScript to change form behaviors? All good, you
                    can use the browser defaults. Try submitting the form below.
                    Depending on your browser and OS, you’ll see a slightly
                    different style of feedback.
                    <br /><br />
                    While these feedback styles cannot be styled with CSS, you
                    can still customize the feedback text through JavaScript.
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <form class="mt-13">
                <v-row>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >First Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="First Name"
                      v-model="firstName"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Last Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="First Name"
                      v-model="lastName"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Username</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Username"
                      v-model="username"
                      required
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >City</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="City"
                      v-model="city"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >State</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="State"
                      v-model="state"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Zip</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Zip"
                      v-model="zip"
                      required
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-checkbox
                  v-model="checkbox"
                  color="#5e72e4"
                  :ripple="false"
                  class="ma-0 checkbox-custom checkbox-thinner mb-5"
                  required
                  hide-details
                >
                  <template v-slot:label>
                    <span class="text-body text-body-2 ls-0 checkbox-validation"
                      >Agree to terms and conditions</span
                    >
                  </template>
                </v-checkbox>

                <v-btn
                  type="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                  >Submit</v-btn
                >
              </form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeaderTopDashboard from "../../components/HeaderTopDashboard.vue";
import { validationMixin } from "vuelidate";
import { required, maxLength, numeric } from "vuelidate/lib/validators";

export default {
  name: "form-validation",
  components: { HeaderTopDashboard },
  data() {
    return {
      checkbox: false,
      firstName: "",
      lastName: "",
      username: "",
      city: "",
      state: "",
      zip: "",
    };
  },
  mixins: [validationMixin],

  validations: {
    firstName: { required, maxLength: maxLength(10) },
    lastName: { required, maxLength: maxLength(10) },
    username: { required, maxLength: maxLength(10) },
    city: { required, maxLength: maxLength(10) },
    state: { required, maxLength: maxLength(10) },
    zip: { required, numeric },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
    },
    clear() {
      this.$v.$reset();
      this.firstName = "";
      this.lastName = "";
      this.username = "";
      this.city = "";
      this.state = "";
      this.zip = "";
      this.checkbox = false;
    },
  },
  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.maxLength &&
        errors.push("First Name must be at most 10 characters long");
      !this.$v.firstName.required && errors.push("First Name is required.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.maxLength &&
        errors.push("Last Name must be at most 10 characters long");
      !this.$v.lastName.required && errors.push("Last Name is required.");
      return errors;
    },
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.maxLength &&
        errors.push("Username must be at most 10 characters long");
      !this.$v.username.required && errors.push("Username is required.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.maxLength &&
        errors.push("City must be at most 10 characters long");
      !this.$v.city.required && errors.push("City is required.");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.maxLength &&
        errors.push("State must be at most 10 characters long");
      !this.$v.state.required && errors.push("State is required.");
      return errors;
    },
    zipErrors() {
      const errors = [];
      if (!this.$v.zip.$dirty) return errors;
      !this.$v.zip.numeric && errors.push("The Zip must be a `number` type");
      !this.$v.zip.required && errors.push("Zip is required.");
      return errors;
    },
  },
};
</script>
